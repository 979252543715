<template>
  <div class="flex flex-col gap-1">
    <BackButton class="mb-1" :route="{ name: 'IngresarCliente'}" />
    <SeleccionClientesActuales @clienteSelected="clienteSelected" @updateClientName="updateClientName" />
    <div v-if="clienteSeleccionado">
      <SeleccionAdmin :admin="adminSeleccionado" store="clientesActuales" class="mb-1" />
      <ArrowButton
        text="ingresar"
        background="#cccccc"
        color="#000000"
        disabled-background="black"
        :disabled="!(adminSeleccionado && adminSeleccionado.id)"
        @onClick="attepmptUpdateClient"
      />
    </div>
    <BlackModal v-if="showIngresoCorrectoModal" text="ingreso correcto" @confirm="finalizar" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions,  } from 'vuex'
import BackButton from '@/components/shared/BackButton.vue'
import ArrowButton from '@/components/shared/ArrowButton.vue'
import SeleccionClientesActuales from '@/components/director/ingresar-cliente/SeleccionClientesActuales.vue'
import SeleccionAdmin from '@/components/director/SeleccionAdmin'
import BlackModal from '@/components/shared/BlackModal.vue'

export default {
  name: 'ClientesActuales',
  components: {
    BackButton,
    ArrowButton,
    SeleccionClientesActuales,
    SeleccionAdmin,
    BlackModal
  },

  data() {
    return {
      showIngresoCorrectoModal: false,
      clientName: '',
    }
  },

  computed: {
    ...mapGetters({ 
      adminSeleccionado: 'clientesActuales/admin', 
      clienteSeleccionado: 'clientesActuales/cliente' 
    })
  },

  beforeRouteLeave(to, from, next) {
    this.cleanState()
    next()
  },

  methods: {
    ...mapMutations({ 
      setCliente: 'clientesActuales/setCliente',
      setAdmin: 'clientesActuales/setAdmin' 
    }),

    ...mapActions({
      updateClient: 'clients/update'
    }),

    clienteSelected(cliente) {
      this.updateClientName(cliente.name)
      this.$store.commit('clientesActuales/setAdmin', { description: cliente.administration })
    },

    updateClientName(value) {
      this.clientName = value;
    },

    async attepmptUpdateClient() {
      const { success } = await this.updateClient({
        id: this.clienteSeleccionado.id,
        name: this.clientName,
        idAdmin: this.adminSeleccionado.id
      })

      if (success) {
        this.showIngresoCorrectoModal = true
      }
    },

    finalizar() {
      this.showIngresoCorrectoModal = false
      this.cleanState()
      this.$router.push({ name: 'IngresarCliente'})
    },

    cleanState() {
      this.setCliente(null)
      this.setAdmin(null)
    }
  }
}
</script>
