<template>
  <div class="cliente-row">
    <!-- Encabezado -->
    <div class="flex-1 overflow-ellipsis overflow-x-hidden py-2 px-3 bg-white cursor-default">
      <span class="cliente-row-item font-nova-semibold"> {{ cliente.name }} </span>
    </div>
    <!-- Menu desplegable -->
    <div class="bg-white">
      <div v-if="!showMenu" class="flex items-center h-full cursor-pointer" @click="showMenu = !showMenu">
        <EllipsisVIcon class="px-3 h-5" />
      </div>
      <div v-else class="relative h-full flex gap-1 z-20">
        <div class="icon-container bg-orange" @click="selectCliente(cliente)">
          <BrushIcon />
        </div>
        <div class="icon-container bg-red-500" @click="attemptDeleteCliente(cliente)">
          <TrashIcon />
        </div>
      </div>
    </div>

    <div
      v-if="showMenu"
      class="fixed top-0 left-0 w-full h-full bg-transparent z-10"
      @click="showMenu = false"
    ></div>
    <BlackModal
      v-if="showModalConfirmDelete"
      text="estás seguro de eliminar cliente"
      :actions="2"
      :success="false"
      @confirm="confirmDelete"
      @cancel="cancelDelete"
      @close="showModalConfirmDelete = false"
    />
    <BlackModal
      v-if="showModalDeleteCompleted"
      text="eliminado con éxito"
      @confirm="closeDeleteCompletedModal"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import BrushIcon from '@/components/shared/icons/BrushIcon.vue'
import TrashIcon from '@/components/shared/icons/TrashIcon.vue'
import EllipsisVIcon from '@/components/shared/icons/EllipsisVIcon.vue'
import BlackModal from '@/components/shared/BlackModal.vue'

export default {
  name: 'SeleccionClienteActualRow',
  components: { BlackModal, BrushIcon, TrashIcon, EllipsisVIcon },
  props: {
    cliente: { type: Object, default: null }
  },
  data() {
    return {
      showMenu: false,
      showModalConfirmDelete: false,
      showModalDeleteCompleted: false,
      modalText: '',
      clientToBeDeleted: null,
    }
  },

  methods: {
    ...mapActions({ deleteClient: 'clients/delete' }),
    ...mapMutations({ setCliente: 'clientesActuales/setCliente' }),

    selectCliente(cliente) {
      this.setCliente(cliente)
      this.$emit('clienteSelected', cliente)
    },

    attemptDeleteCliente(cliente) {
      this.clientToBeDeleted = cliente
      this.showMenu = false
      this.showModalConfirmDelete = true
    },

    cancelDelete() {
      this.clientToBeDeleted = null
      this.showModalConfirmDelete = false
    },

    async confirmDelete() {
      const { success } = await this.deleteClient(this.clientToBeDeleted.id)
      if (success) {
        this.showModalDeleteCompleted = true
      }
    },

    closeDeleteCompletedModal() {
      this.showModalDeleteCompleted = fasle
    }
  }
}
</script>

<style scoped>
.cliente-row {
  @apply flex justify-between gap-1 text-lg;
}
.cliente-row-item {
  @apply bg-white uppercase;
  white-space: nowrap;
}
.icon-container {
  @apply flex items-center justify-center px-3 w-12 cursor-pointer;
}
.bg-orange {
  background-color: rgb(255, 143, 0);
}
</style>
