<template>
  <div class="relative">
    <div v-if="clienteSeleccionado" >
      <div class="bg-white uppercase px-2 py-1 mb-1" >
        <input type="text" class="font-nova-light-italic w-full px-1 py-2" :value="clienteSeleccionado.name" @input="updateClientName" />
      </div>
    </div>
    <div v-else class="flex flex-col gap-1 w-full top-12 z-10">
      <SeleccionClienteActualRow
        v-for="client in clients"
        :key="client.id"
        :cliente="client"
        @clienteSelected="clienteSelected"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SeleccionClienteActualRow from '@/components/director/ingresar-cliente/SeleccionClienteActualRow.vue'

export default {
  name: 'SeleccionClientesActuales',
  components: { SeleccionClienteActualRow },
  computed: {
    ...mapGetters({ 
      clients: 'clients/clients',
      clienteSeleccionado: 'clientesActuales/cliente' 
    })
  },

  async mounted() {
    if (!this.clients ){
      await this.getClients()
    }
  },

  methods: {
    ...mapActions({ getClients: 'clients/getClients' }),

    updateClientName(event) {
      this.$emit('updateClientName', event.target.value)
    },

    clienteSelected(cliente) {
      this.$emit('clienteSelected', cliente)
      // this.$nextTick(()=>{
      //   this.$refs.input.select()
      //   this.$refs.input.focus()
      // })
    },
  }
}
</script>
